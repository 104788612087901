*, :before, :after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html, body {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  line-height: 1;
}

img, picture, video, canvas, svg {
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  word-wrap: break-word;
}

a {
  color: inherit;
  text-decoration: none;
}

:root {
  --RS-space-1: 1px;
  --RS-space-2: 2px;
  --RS-space-4: 4px;
  --RS-space-6: 6px;
  --RS-space-8: 8px;
  --RS-space-12: 12px;
  --RS-space-16: 16px;
  --RS-space-24: 24px;
  --RS-space-32: 32px;
  --RS-space-48: 48px;
  --RS-space-64: 64px;
  --RS-space-96: 96px;
  --RS-space-128: 128px;
  --RS-space-192: 192px;
  --RS-space-256: 256px;
  --RS-space-1-n: -1px;
  --RS-space-2-n: -2px;
  --RS-space-4-n: -4px;
  --RS-space-6-n: -6px;
  --RS-space-8-n: -8px;
  --RS-space-12-n: -12px;
  --RS-space-16-n: -16px;
  --RS-space-24-n: -24px;
  --RS-space-32-n: -32px;
  --RS-space-48-n: -48px;
  --RS-space-64-n: -64px;
  --RS-space-96-n: -96px;
  --RS-space-128-n: -128px;
  --RS-space-192-n: -192px;
  --RS-space-256-n: -256px;
  --RS-col-1: 160px;
  --RS-col-2: 320px;
  --RS-col-3: 480px;
  --RS-col-4: 640px;
  --RS-col-5: 800px;
  --RS-col-6: 960px;
  --RS-col-7: 1120px;
  --RS-col-8: 1280px;
  --RS-the-red-color: #b92740;
  --RS-space-black-color: #100f0f;
  --RS-space-grey-color: #1a1919;
  --RS-space-grey-color-alt-bright: #211f1f;
  --RS-white-color: #f7f5f2;
  --RS-mid-grey-color: #3b3838;
  --RS-soft-yellow-color: #f9f986;
  --RS-soft-pink-color: #fecdcd;
  --RS-bright-red-color: #d9465f;
  --RS-brand-color-plumresearch: #7977b9;
  --RS-brand-color-naturalantibody: #67c894;
  --RS-brand-color-streamcards: #ccc;
  --RS-brand-color-selmo: #ccc;
  --RS-brand-color-sentryc: #b14174;
  --RS-brand-color-skinwallet: #ccc;
  --RS-brand-color-traceroute42: #182ad1;
  --RS-brand-color-shortest: #6fbca2;
  --RS-brand-color-centrummk: #ccc;
  --RS-brand-color-challengeme: #f44e24;
  --RS-brand-color-remotemyapp: #ccc;
  --RS-brand-color-teslathemes: #afa169;
  --RS-brand-color-zety: #3983fa;
  --RS-brand-color-gamerhash: #e84341;
  --RS-brand-color-adrino: #49b649;
  --RS-brand-color-unikrn: #ccc;
  --RS-brand-color-greedeals: #39de7e;
  --RS-brand-color-newzmate: #49a7db;
  --RS-brand-color-gamivo: #f47b25;
  --RS-brand-color-contentation: #13928b;
  --RS-anim-delay: 50ms;
  --RS-default-transition: cubic-bezier(.39, .575, .565, 1);
  --RS-bounce-transition: cubic-bezier(.175, .885, .32, 1.275);
  --RS-anim-duration-xs: 75ms;
  --RS-anim-duration-sm: .15s;
  --RS-anim-duration-md: .225s;
  --RS-anim-duration-lg: .35s;
  --RS-anim-duration-xlg: .6s;
  --RS-anim-intro-artwork-delay: 1.25s;
  --RS-anim-full-rotate-duration: 90s;
  --RS-line-height-sm: 1;
  --RS-line-height-md: 1.21;
  --RS-line-height-lg: 1.34;
  --RS-line-height-xlg: 1.55;
  --RS-font-size-h1: clamp(1.5rem, 1.237rem + 1.1688vw, 2.625rem);
  --RS-font-size-h1-desktop: clamp(2.5rem, 2.1129rem + .4839vw, 2.6875rem);
  --RS-font-size-h1-intro: clamp(2rem, 1.5617rem + 1.9481vw, 3.875rem);
  --RS-font-size-h1-intro-desktop: clamp(3.625rem, 3.1089rem + .6452vw, 3.875rem);
  --RS-font-size-h2: clamp(1.3125rem, 1.2468rem + .2922vw, 1.59375rem);
  --RS-font-size-h2-desktop: clamp(1.5rem, 1.2419rem + .3226vw, 1.625rem);
  --RS-font-size-h3: clamp(.75rem, .7135rem + .1623vw, .90625rem);
  --RS-font-size-h3-desktop: clamp(.875rem, .746rem + .1613vw, .9375rem);
  --RS-font-size-top-nav: clamp(1rem, .9854rem + .0649vw, 1.0625rem);
  --RS-font-size-mobile-nav: clamp(2.625rem, 2.2772rem + 1.7391vw, 3.125rem);
  --RS-font-size-p: clamp(.9375rem, .9083rem + .1299vw, 1.0625rem);
  --RS-font-size-p-desktop: clamp(1.03125rem, .9022rem + .1613vw, 1.09375rem);
  --RS-font-size-label: clamp(.875rem, .8531rem + .0974vw, .96875rem);
  --RS-font-size-label-desktop: clamp(.9375rem, .8085rem + .1613vw, 1rem);
  --RS-font-size-stat: clamp(2.5rem, 2.2516rem + 1.1039vw, 3.5625rem);
  --RS-font-size-disclaimer: clamp(.75rem, .7354rem + .0649vw, .8125rem);
  --RS-font-weight-variable-regular: 375;
  --RS-font-weight-variable-bold: 525;
  --RS-brand-bg-xy: calc(var(--RS-space-24)  - var(--RS-space-8));
  --RS-rotate-firefox-fix: 0deg;
}

@font-face {
  font-family: Satoshi-Variable;
  src: url("../Satoshi-Variable.f6ec8385.woff2") format("woff2"), url("../Satoshi-Variable.98597179.woff") format("woff"), url("../Satoshi-Variable.e97601a1.ttf") format("truetype");
  font-weight: 100 1000;
  font-stretch: 75% 125%;
  font-display: swap;
}

html {
  font-size: 100%;
}

body {
  font-family: Satoshi-Variable, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif;
  font-size: 16px;
  font-weight: var(--RS-font-weight-variable-regular);
  color: var(--RS-white-color);
  letter-spacing: 0;
  background-color: var(--RS-space-black-color);
  font-feature-settings: "ss01";
  line-height: 1;
  transition: none;
  overflow-x: hidden;
  overflow-y: auto;
}

body > main {
  width: 100%;
  min-height: 1px;
  overflow: hidden;
}

a {
  -webkit-tap-highlight-color: transparent;
  outline: none;
}

@media screen and (min--moz-device-pixel-ratio: 0) {
  :root {
    --RS-rotate-firefox-fix: .03deg;
  }

  svg, path {
    outline: 1px solid #fff0;
  }

  html {
    scrollbar-width: thin;
  }

  ::-moz-selection {
    color: var(--RS-white-color);
    background: var(--RS-the-red-color);
  }
}

::selection {
  color: var(--RS-white-color);
  background: var(--RS-the-red-color);
}

body::-webkit-scrollbar {
  width: var(--RS-space-6);
  height: var(--RS-space-4);
  background-color: var(--RS-space-black-color);
}

body::-webkit-scrollbar-thumb {
  background: var(--RS-white-color);
  border-radius: 4px;
}

svg:not(:root).svg-inline--fa {
  overflow: visible;
}

.svg-inline--fa {
  height: 1em;
  font-size: inherit;
  vertical-align: -.125em;
  display: inline-block;
  overflow: visible;
}

.svg-inline--fa.fa-w-14 {
  width: .875em;
}

.svg-inline--fa.fa-w-16 {
  width: 1em;
}

[role="button"] {
  cursor: pointer;
}

:focus {
  outline: medium none invert;
  outline: initial;
}

svg path, svg g {
  transform-box: fill-box;
  transform-origin: center;
}

img, video {
  width: 100%;
  height: auto;
  outline: 1px solid #0000;
}

ul {
  list-style: none;
}

.RS-typeform {
  font-family: Satoshi-Variable, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif;
}

.RS-contact-form-wrapper {
  min-width: 100%;
  justify-content: center;
  align-items: center;
  margin: 4vh auto;
  display: flex;
  position: relative;
}

@media only screen and (min-width: 1024px) {
  .RS-contact-form-wrapper {
    min-width: 33%;
    margin: 6vh auto 0;
  }
}

p, h1, h2, h3, h4, h5, h6 {
  font-weight: var(--RS-font-weight-variable-regular);
}

.RS-simple-link {
  text-decoration: underline;
  transition: color .35s;
}

.RS-simple-link:hover, .RS-simple-link.focus-visible, .RS-simple-link:hover, .RS-simple-link.focus-visible {
  color: var(--RS-bright-red-color);
}

.RS-link {
  color: inherit;
  padding-bottom: var(--RS-space-2);
  text-decoration: none;
  position: relative;
}

.RS-section-founder-team .RS-link:last-of-type {
  padding-bottom: var(--RS-space-8);
  gap: var(--RS-space-8);
  display: flex;
}

.RS-link:before {
  content: "";
  width: 100%;
  height: var(--RS-space-2);
  bottom: var(--RS-space-2-n);
  border-radius: var(--RS-space-8);
  transform-origin: 100%;
  transition: transform var(--RS-anim-duration-lg) var(--RS-default-transition);
  background: var(--RS-white-color);
  position: absolute;
  left: 0;
  transform: scaleX(0);
}

.RS-link:hover:before, .RS-link.focus-visible:before, .RS-link:hover:before, .RS-link.focus-visible:before {
  transform-origin: 0;
  transform: scaleX(1);
}

@media only screen and (min-width: 860px) {
  .RS-link--active:before, .RS-link--active:hover:before, .RS-link--active.focus-visible:before, .RS-link--active:before, .RS-link--active:hover:before, .RS-link--active.focus-visible:before {
    transform-origin: 0;
    transform: scaleX(.5);
  }
}

.RS-no-wrap {
  white-space: nowrap;
}

.RS-br-xs {
  display: inline;
  display: initial;
}

@media only screen and (min-width: 480px) {
  .RS-br-xs {
    display: none;
  }
}

.RS-br-sm {
  display: inline;
  display: initial;
}

@media only screen and (min-width: 860px) {
  .RS-br-sm {
    display: none;
  }
}

@media only screen and (min-width: 480px) {
  .RS-br-md {
    display: none;
  }
}

@media only screen and (min-width: 1024px) {
  .RS-br-md {
    display: inline;
    display: initial;
  }
}

.RS-br-lg {
  display: none;
}

@media only screen and (min-width: 1024px) {
  .RS-br-lg {
    display: inline;
    display: initial;
  }
}

.RS-inline-icon {
  font-size: 80%;
  line-height: 80%;
  display: inline-block;
}

.RS-inline-icon-external-link {
  transition: transform var(--RS-anim-duration-lg) var(--RS-default-transition);
  font-size: 90%;
  line-height: 90%;
}

.RS-inline-icon-external-link svg path {
  fill: var(--RS-bright-red-color);
}

.RS-job-listing .RS-inline-icon-external-link {
  transform: rotateZ(-45deg);
}

@media only screen and (min-width: 860px) {
  .RS-job-listing .RS-inline-icon-external-link {
    transform: none;
  }
}

.RS-job-listing:hover .RS-inline-icon-external-link, .RS-job-listing.focus-visible .RS-inline-icon-external-link, .RS-job-listing:hover .RS-inline-icon-external-link, .RS-job-listing.focus-visible .RS-inline-icon-external-link {
  transform: rotateZ(-45deg);
}

.Typewriter__cursor {
  transform-origin: bottom;
  display: inline-flex;
  transform: scaleY(.9)scaleX(.8);
}

.RS-font-weight-bold {
  font-weight: var(--RS-font-weight-variable-bold);
}

.RS-font-weight-regular {
  font-weight: var(--RS-font-weight-variable-regular);
}

.RS-font-style-italic {
  display: inline-flex;
  transform: skewX(-8deg);
}

.RS-section {
  width: 100%;
  padding: 6vh var(--RS-space-24) 8vh;
}

@media only screen and (min-width: 860px) {
  .RS-section {
    padding: 6vh var(--RS-space-24) 9vh;
  }
}

@media only screen and (min-width: 1024px) {
  .RS-section {
    padding: 9vh var(--RS-space-32) 12vh;
  }

  .RS-section-contact {
    padding: 18vh var(--RS-space-32) 9vh;
  }
}

.RS-container {
  max-width: var(--RS-col-8);
  margin: 0 auto;
  position: relative;
}

.RS-container.RS-container--narrow {
  max-width: var(--RS-col-4);
}

@media only screen and (min-width: 1024px) {
  .RS-section-founder-team .RS-container {
    gap: calc(var(--RS-space-96)  - var(--RS-space-16));
    flex-direction: row-reverse;
    align-items: center;
    display: flex;
  }
}

.RS-section-founder-blog-showcase .RS-container {
  min-height: 100vh;
}

.RS-animation {
  opacity: .005;
  transition-timing-function: var(--RS-default-transition);
  transform-style: preserve-3d;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transform: translate3d(0, 0, 0);
}

.RS-animation__slow {
  transition-duration: 1s;
}

.RS-animation.RS-animation--enter {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

svg.RS-animation.RS-animation--enter {
  transform: translate3d(0, 0, 0) rotate(var(--RS-rotate-firefox-fix));
}

.RS-animation__text-to-top {
  transform: translate3d(0, 8px, 0);
}

.RS-animation__text-to-bottom {
  transform: translate3d(0, -13px, 0);
}

.RS-animation__text-to-left {
  transform: translate3d(13px, 0, 0);
}

.RS-animation__text-to-right {
  transform: translate3d(-8px, 0, 0);
}

.RS-animation__image-to-right {
  transform: translate3d(89px, -34px, 0);
}

.RS-animation__image-to-left {
  transform: translate3d(-89px, -34px, 0);
}

.RS-animation__image-to-top {
  transform: translate3d(0, 55px, 0);
}

.RS-animation__image-to-bottom {
  transform: translate3d(0, -55px, 0);
}

.RS-animation__artwork-content {
  transform: translate3d(0, 0, 0) rotate(var(--RS-rotate-firefox-fix));
  animation: 6s cubic-bezier(.39, .575, .565, 1) infinite floatArtworkContent;
}

.RS-animation__artwork-content--reverse {
  animation-name: floatArtworkContentReverse;
}

@keyframes floatArtworkContent {
  50% {
    transform: translate3d(0, 21px, 0) rotate(var(--RS-rotate-firefox-fix));
  }
}

@keyframes floatArtworkContentReverse {
  50% {
    transform: translate3d(0, -21px, 0) rotate(var(--RS-rotate-firefox-fix));
  }
}

@keyframes floatArtworkShadow {
  50% {
    filter: blur(5px);
  }
}

.RS-stagger--1 {
  transition-delay: calc(var(--RS-anim-delay) * 1);
}

.RS-stagger--2 {
  transition-delay: calc(var(--RS-anim-delay) * 2);
}

.RS-stagger--3 {
  transition-delay: calc(var(--RS-anim-delay) * 3);
}

.RS-stagger--4 {
  transition-delay: calc(var(--RS-anim-delay) * 4);
}

.RS-stagger--5 {
  transition-delay: calc(var(--RS-anim-delay) * 5);
}

.RS-stagger--6 {
  transition-delay: calc(var(--RS-anim-delay) * 6);
}

.RS-stagger--7 {
  transition-delay: calc(var(--RS-anim-delay) * 7);
}

.RS-stagger--8 {
  transition-delay: calc(var(--RS-anim-delay) * 8);
}

.RS-stagger--connect {
  transition-delay: calc(var(--RS-anim-delay) * 14);
}

.RS-animation__artwork-content--careers__all {
  transform: translate3d(0, 0, 0) rotate(var(--RS-rotate-firefox-fix));
  animation: 3s linear infinite animArtworkContentCareersAll;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: top;
}

@keyframes animArtworkContentCareersAll {
  25% {
    transform: translate3d(-5px, -3px, 0)rotateZ(-.5deg)scale(.995);
  }

  75% {
    transform: translate3d(3px, 2px, 0)rotateZ(.5deg)scale(1);
  }
}

.RS-animation__artwork-content--careers__ship {
  transform: translate3d(0, 0, 0) rotate(var(--RS-rotate-firefox-fix));
  animation: .5s linear infinite animArtworkContentCareersShip;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: bottom;
}

@keyframes animArtworkContentCareersShip {
  33%, 67% {
    transform: translate3d(0, 2px, 0)scaleY(.99);
  }
}

.RS-anim-ship-lamp {
  opacity: 0;
  animation: 4.25s linear infinite animArtworkContentShipLamp;
  transform: translate3d(0, 0, 0);
}

@keyframes animArtworkContentShipLamp {
  50%, 70% {
    opacity: 1;
  }

  40%, 80% {
    opacity: 0;
  }
}

.RS-anim-chewbacca-eye-left, .RS-anim-chewbacca-eye-right, .RS-anim-astro-woman-eye-left, .RS-anim-astro-woman-eye-right {
  animation: 2s linear infinite animArtworkContentChewbaccaEye;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform: translate3d(0, 0, 0);
}

@keyframes animArtworkContentChewbaccaEye {
  30%, 70% {
    transform: translate3d(0, 0, 0)scaleY(.2);
  }

  25%, 35%, 65%, 75% {
    transform: translate3d(0, 0, 0);
  }
}

.RS-anim-dude-hat {
  animation: 3s linear infinite animArtworkContentDudeHat;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: bottom;
  transform: translate3d(0, 0, 0);
}

@keyframes animArtworkContentDudeHat {
  30% {
    transform: translate3d(1px, -13px, 0)rotateZ(-5deg)scaleX(.98)scaleY(.96)skewX(5deg);
  }

  25%, 35%, 65%, 75% {
    transform: translate3d(0, 0, 0);
  }
}

.RS-anim-dude-mouth {
  animation: 3s linear infinite animArtworkContentDudeMouth;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform: translate3d(0, 0, 0);
}

@keyframes animArtworkContentDudeMouth {
  30% {
    transform: scaleX(.2)scaleY(3);
  }

  25%, 35%, 65%, 75% {
    transform: translate3d(0, 0, 0);
  }
}

.RS-anim-pumpkin-head {
  animation: 2s linear infinite animPumpkinHead;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform: translate3d(0, 0, 0);
}

@keyframes animPumpkinHead {
  30% {
    transform: rotateZ(2deg)translateY(1px);
  }

  70% {
    transform: rotateZ(-3deg);
  }
}

.RS-anim-pumpkin-eye-left, .RS-anim-pumpkin-eye-right {
  animation: 6.5s linear infinite animArtworkContentPumpkinEye;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  opacity: 0;
  transform: translate3d(0, 0, 0);
}

@keyframes animArtworkContentPumpkinEye {
  50%, 70% {
    opacity: 1;
  }

  40%, 80% {
    opacity: 0;
  }
}

.RS-anim-robot-head {
  animation: 3.5s linear infinite animArtworkContentRobotHead;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: bottom;
  transform: translate3d(0, 0, 0);
}

@keyframes animArtworkContentRobotHead {
  30% {
    transform: translate3d(0, 5px, 0)scaleX(.99);
  }

  70% {
    transform: translate3d(0, 8px, 0)scaleX(.98);
  }

  25%, 35%, 65%, 75% {
    transform: translate3d(0, 0, 0);
  }
}

.RS-anim-robot-eye-right {
  animation: 3.55s linear infinite animArtworkContentRobotEye;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  opacity: 0;
  transform: translate3d(0, 0, 0);
}

@keyframes animArtworkContentRobotEye {
  30%, 70% {
    opacity: 1;
  }

  25%, 35%, 65%, 75% {
    opacity: 0;
  }
}

.RS-anim-woman-eye-left, .RS-anim-woman-eye-right, .RS-anim-astro-man-eye-left, .RS-anim-astro-man-eye-right {
  animation: 3.5s linear infinite animArtworkContentWomanEye;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform: translate3d(0, 0, 0);
}

@keyframes animArtworkContentWomanEye {
  45%, 55% {
    transform: translate3d(0, 0, 0)scaleY(.2);
  }

  42.5%, 47.5%, 52.5%, 57.5% {
    transform: translate3d(0, 0, 0);
  }
}

.RS-anim-woman-mouth {
  animation: 4.25s linear infinite animArtworkContentWomanMouth;
  transform: translate3d(0, 0, 0);
}

@keyframes animArtworkContentWomanMouth {
  30% {
    transform: scaleX(1.1)scaleY(.6);
  }

  25%, 35%, 65%, 75% {
    transform: translate3d(0, 0, 0);
  }
}

.RS-anim-startrek-brow-left, .RS-anim-startrek-brow-right {
  animation: 4s linear infinite animArtworkContentStartrekBrow;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: 0;
  transform: translate3d(0, 0, 0);
}

@keyframes animArtworkContentStartrekBrow {
  45% {
    transform: rotateZ(-21deg)scale(.8)translateY(0);
  }

  30%, 60% {
    transform: translate3d(0, 0, 0);
  }
}

.RS-anim-startrek-ear {
  animation: 4s linear infinite animArtworkContentStartrekEar;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: 0;
  transform: translate3d(0, 0, 0);
}

@keyframes animArtworkContentStartrekEar {
  45% {
    transform: rotateZ(8deg)scale(1.05, 1.3);
  }

  30%, 60% {
    transform: translate3d(0, 0, 0);
  }
}

.RS-anim-godzilla-scale {
  animation: 4s linear infinite animArtworkContentGodzillaScale;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  opacity: 0;
  transform: translate3d(0, 0, 0);
}

@keyframes animArtworkContentGodzillaScale {
  45% {
    opacity: 0;
  }

  30%, 60% {
    opacity: 1;
  }
}

.RS-anim-godzilla-eye {
  animation: 4s linear infinite animArtworkContentGodzillaEye;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  opacity: 0;
  transform: translate3d(0, 0, 0);
}

@keyframes animArtworkContentGodzillaEye {
  45% {
    opacity: 1;
    transform: scaleY(2.5);
  }

  30%, 60% {
    opacity: 0;
  }
}

.RS-anim-godzilla-hand {
  animation: 3s linear infinite animArtworkContentGodzillaHand;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: 100% 0;
  transform: translate3d(0, 0, 0);
}

@keyframes animArtworkContentGodzillaHand {
  45% {
    transform: rotateZ(3deg)scaleX(.98)scaleY(.99)translateX(3px);
  }

  30%, 60% {
    transform: translate3d(0, 0, 0);
  }
}

.RS-anim-hoverboard-all {
  animation: 4s infinite animArtworkContentHoverboardAll;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: 0 100%;
  animation-direction: alternate;
  transform: translate3d(0, 0, 0);
}

@keyframes animArtworkContentHoverboardAll {
  0% {
    transform: translate3d(0, 0, 0)rotateX(0)scaleY(1);
  }

  100% {
    transform: translate3d(-89px, 5px, 0)rotateX(-5deg);
  }
}

.RS-anim-bicycle-all {
  animation: 4s infinite animArtworkContentBicycleAll;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: bottom;
  animation-direction: alternate;
  transform: translate3d(0, 0, -5px);
}

@keyframes animArtworkContentBicycleAll {
  0% {
    transform: translate3d(0, 0, -120px)rotateX(0)scaleY(1);
  }

  100% {
    transform: translate3d(55px, -8px, -120px)rotateX(8deg)scaleY(.995);
  }
}

.RS-anim-bicycle {
  animation: .35s linear infinite animArtworkContentBicycle;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: bottom;
  animation-direction: alternate;
  transform: translate3d(0, 0, 0);
}

@keyframes animArtworkContentBicycle {
  0%, 100% {
    transform: translate3d(1px, 1px, 0)scaleY(1);
  }

  33%, 67% {
    transform: translate3d(1px, 1px, 0)scaleY(.998);
  }
}

.RS-anim-hoverboard {
  animation: .35s linear infinite animArtworkContentHoverboard;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: 0 100%;
  transform: translate3d(0, 0, 0);
}

@keyframes animArtworkContentHoverboard {
  0%, 100% {
    transform: translate3d(1px, 1px, 0)skewY(0);
  }

  33%, 67% {
    transform: translate3d(1px, 1px, 0)skewY(.25deg);
  }
}

.RS-anim-astro-man-brow-left, .RS-anim-astro-man-brow-right {
  animation: 4s linear infinite animArtworkContentAstroManBrow;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: 0;
  transform: translate3d(0, 0, 0);
}

.RS-anim-astro-man-brow-right {
  transform-origin: center;
}

@keyframes animArtworkContentAstroManBrow {
  45% {
    transform: rotateZ(-8deg)scale(.8)translateY(3px);
  }

  30%, 60% {
    transform: translate3d(0, 0, 0);
  }
}

.RS-anim-astro-woman-brow-left, .RS-anim-astro-woman-brow-right {
  animation: 3.5s linear infinite animArtworkContentAstroWomanBrow;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: 0;
  transform: translate3d(0, 0, 0);
}

.RS-anim-astro-man-brow-right {
  transform-origin: 100%;
}

@keyframes animArtworkContentAstroWomanBrow {
  45% {
    transform: rotateZ(13deg)scale(1.05)translateY(-1px);
  }

  30%, 60% {
    transform: translate3d(0, 0, 0);
  }
}

.RS-anim-astro-man-mouth {
  animation: 3.5s linear infinite animArtworkContentAstroManMouth;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: 0;
  transform: translate3d(0, 0, 0);
}

@keyframes animArtworkContentAstroManMouth {
  45% {
    transform: rotateZ(-3deg)scaleX(1.2);
  }

  30%, 60% {
    transform: translate3d(0, 0, 0);
  }
}

.RS-anim-astro-woman-mouth {
  animation: 3.5s linear infinite animArtworkContentAstroWomanMouth;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: 0;
  transform: translate3d(0, 0, 0);
}

@keyframes animArtworkContentAstroWomanMouth {
  45% {
    transform: rotateZ(3deg)scaleX(.8)scaleY(1.2);
  }

  30%, 60% {
    transform: translate3d(0, 0, 0);
  }
}

.RS-anim-bicycle-lamp, .RS-anim-hoverboard-lamp {
  animation: 1.25s linear infinite animArtworkContentAstroLamp;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  opacity: 0;
  transform: translate3d(0, 0, 0);
}

@keyframes animArtworkContentAstroLamp {
  50%, 70% {
    opacity: 1;
  }

  40%, 80% {
    opacity: 0;
  }
}

.RS-anim-astro-man-face {
  animation: 3.5s linear infinite animArtworkContentAstroManFace;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform: translate3d(0, 0, 0);
}

@keyframes animArtworkContentAstroManFace {
  33% {
    transform: rotateZ(-3deg);
  }

  67% {
    transform: rotateZ(5deg);
  }
}

.RS-anim-astro-woman-face {
  animation: 3.5s linear infinite animArtworkContentAstroWomanFace;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform: translate3d(0, 0, 0);
}

@keyframes animArtworkContentAstroWomanFace {
  33% {
    transform: rotateZ(5deg);
  }

  67% {
    transform: rotateZ(-3deg);
  }
}

.RS-anim-astro-woman-hand-left {
  animation: 2.5s linear infinite animArtworkContentAstroWomanHandLeft;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform: translate3d(0, 0, 0);
}

@keyframes animArtworkContentAstroWomanHandLeft {
  33% {
    transform: rotateZ(5deg)scaleY(.95);
  }

  67% {
    transform: rotateZ(-3deg)scaleY(1.05);
  }
}

.RS-anim-astro-man-hand-right {
  animation: 2s linear infinite animArtworkContentAstroManHandRight;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform: translate3d(0, 0, 0);
}

@keyframes animArtworkContentAstroManHandRight {
  33% {
    transform: rotateZ(-1deg)scaleY(.9);
  }

  67% {
    transform: rotateZ(1deg)scaleY(1.05);
  }
}

.RS-anim-astro-woman-screen-line {
  animation: 1.25s linear infinite animArtworkContentAstroWomanScreenLine;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform: translate3d(0, 0, 0);
}

.RS-anim-astro-woman-screen-line--second {
  animation-delay: calc(var(--RS-anim-intro-artwork-delay)  + 75ms);
}

.RS-anim-astro-woman-screen-line--third {
  animation-delay: calc(var(--RS-anim-intro-artwork-delay)  + .15s);
}

@keyframes animArtworkContentAstroWomanScreenLine {
  0% {
    opacity: 0;
    transform: translate3d(0, 0, 0);
  }

  33% {
    opacity: 0;
    transform: scale(1)translateY(5px);
  }

  50% {
    opacity: 1;
  }

  67% {
    opacity: 0;
    transform: scale(1)translateY(-3px);
  }

  100% {
    opacity: 0;
    transform: translate3d(0, 0, 0);
  }
}

.RS-anim-astro-woman-screen-rectangle {
  animation: 2.25s linear infinite animArtworkContentAstroWomanScreenRectangle;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform: translate3d(0, 0, 0);
}

@keyframes animArtworkContentAstroWomanScreenRectangle {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  33% {
    opacity: 1;
    transform: scale(.5);
  }

  67% {
    transform: translateX(-28px)translateY(13px)scale(.67);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.RS-anim-arcade-button {
  animation: 1s linear infinite animArtworkContentArcadeButton;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: bottom;
  transform: translate3d(0, 0, 0);
}

@keyframes animArtworkContentArcadeButton {
  20% {
    transform: translateY(5px)scaleY(.9)scaleX(.96);
  }

  40% {
    transform: translate3d(0, 0, 0);
  }
}

.RS-anim-arcade-button--second {
  animation-delay: calc(var(--RS-anim-intro-artwork-delay)  + .125s);
}

.RS-anim-arcade-button--third {
  animation-delay: calc(var(--RS-anim-intro-artwork-delay)  + .25s);
}

.RS-anim-arcade-button--fourth {
  animation-delay: calc(var(--RS-anim-intro-artwork-delay)  + .375s);
}

.RS-anim-arcade-joystick {
  animation: 1.25s linear infinite animArtworkContentArcadeJoystick;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: bottom;
  transform: rotateZ(-13deg);
}

@keyframes animArtworkContentArcadeJoystick {
  0% {
    transform: rotateZ(-13deg);
  }

  50% {
    transform: rotateZ(13deg);
  }

  100% {
    transform: rotateZ(-13deg);
  }
}

.RS-anim-arcade-stick {
  animation: 2s linear infinite animArtworkContentArcadeStick;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: bottom;
  transform: translate3d(0, 0, 0);
}

@keyframes animArtworkContentArcadeStick {
  20% {
    transform: rotateZ(0);
  }

  60% {
    transform: rotateZ(-55deg);
  }

  10%, 30%, 40%, 70% {
    transform: translate3d(0, 0, 0);
  }
}

.RS-anim-arcade-hand {
  animation: 1.75s linear infinite animArtworkContentArcadeHand;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: 0 0;
  transform: translate3d(0, 0, 0);
}

@keyframes animArtworkContentArcadeHand {
  30%, 50% {
    transform: rotateZ(2.5deg)scale(.98);
  }

  70%, 80% {
    transform: rotateZ(1.5deg)scale(.97);
  }

  20%, 60%, 100% {
    transform: translate3d(0, 0, 0);
  }
}

.RS-anim-arcade-cable {
  animation: 1.75s linear infinite animArtworkContentArcadeCable;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  opacity: 0;
  transform: translate3d(0, 0, 0);
}

@keyframes animArtworkContentArcadeCable {
  30%, 50%, 80% {
    opacity: 1;
  }

  20%, 60%, 100% {
    opacity: 0;
  }
}

.RS-anim-arcade-all {
  animation: 5s linear infinite animArtworkContentArcadeAll;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform: translate3d(0, 0, 0);
}

@keyframes animArtworkContentArcadeAll {
  33% {
    transform: translateX(2px)translateY(2px);
  }

  67% {
    transform: translateX(-2px)translateY(-2px);
  }
}

.RS-anim-arcade-screen-thcp {
  animation: 2.25s linear infinite animArtworkContentAstroWomanScreenThcp;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform: translate3d(0, 0, 0);
}

@keyframes animArtworkContentAstroWomanScreenThcp {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  33% {
    opacity: 1;
    transform: translateX(-8px)translateY(-21px)scale(.96);
  }

  67% {
    transform: translateX(5px)translateY(13px)scale(.96);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.RS-anim-arcade-screen-rs {
  animation: 2.25s linear infinite animArtworkContentArcadeScreenRs;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  animation-direction: reverse;
  transform: translate3d(0, 0, 0);
}

@keyframes animArtworkContentArcadeScreenRs {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  33% {
    opacity: 1;
    transform: translateX(-8px)translateY(-21px)scale(.96);
  }

  67% {
    transform: translateX(5px)translateY(13px)scale(.96);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.RS-anim-arcade-screen-x {
  animation: .75s linear infinite animArtworkContentArcadeScreenX;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform: translate3d(0, 0, 0);
}

@keyframes animArtworkContentArcadeScreenX {
  0%, 100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  50% {
    opacity: .9;
    transform: scale(.9);
  }
}

.RS-anim-handshake-human {
  animation: 1s linear infinite animArtworkContentHandshakeHuman;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: 100% 100%;
  animation-direction: alternate;
  transform: translate3d(0, 0, 0);
}

@keyframes animArtworkContentHandshakeHuman {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: rotateZ(-2deg)scaleY(.98)translateY(1px);
  }
}

.RS-anim-handshake-robot {
  animation: 1s linear infinite animArtworkContentHandshakeRobot;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: 0 100%;
  animation-direction: alternate;
  transform: translate3d(0, 0, 0);
}

@keyframes animArtworkContentHandshakeRobot {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: rotateZ(1deg)translateY(1px);
  }
}

.RS-anim-handshake-hand-diode {
  animation: 3.25s linear infinite animArtworkContentHandshakeDiode;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: top;
  opacity: 0;
}

@keyframes animArtworkContentHandshakeDiode {
  50%, 70% {
    opacity: 1;
  }

  40%, 80% {
    opacity: 0;
  }
}

.RS-anim-handshake-hand-diode.second {
  animation-delay: calc(var(--RS-anim-intro-artwork-delay)  + 75ms);
}

.RS-anim-handshake-hand-diode.third {
  animation-delay: calc(var(--RS-anim-intro-artwork-delay)  + .15s);
}

.RS-anim-handshake-robot-screen-line {
  animation: 2s linear infinite animArtworkContentHandshakeScreenLine;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform: translate3d(0, 0, 0);
}

@keyframes animArtworkContentHandshakeScreenLine {
  0%, 25%, 75%, 100% {
    transform: translate3d(0, 0, 0);
  }

  50% {
    transform: translateX(21px)translateY(5px);
  }
}

.RS-anim-handshake-robot-screen-dot {
  animation: 2s linear infinite animArtworkContentHandshakeScreenDot;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform: translate3d(0, 0, 0);
}

@keyframes animArtworkContentHandshakeScreenDot {
  0%, 20%, 80%, 100% {
    opacity: 1;
    transform: scaleY(1);
  }

  40%, 60% {
    opacity: 0;
    transform: scaleY(.13);
  }
}

.RS-anim-handshake-robot-screen-dot.third {
  animation-delay: calc(var(--RS-anim-intro-artwork-delay)  + .15s);
}

.RS-anim-handshake-robot-screen-dot.fourth, .RS-anim-handshake-robot-screen-dot.fifth {
  animation-delay: calc(var(--RS-anim-intro-artwork-delay)  + .3s);
}

.RS-anim-handshake-robot-cable {
  animation: 2s linear infinite animArtworkContentHandshakeCable;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  opacity: 0;
}

@keyframes animArtworkContentHandshakeCable {
  0%, 30%, 45%, 65%, 72.5%, 100% {
    opacity: 0;
  }

  35%, 40%, 67.5%, 70% {
    opacity: 1;
  }
}

.RS-anim-journal-portal {
  animation: 2s linear infinite animArtworkContentJournalPortal;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: bottom;
}

@keyframes animArtworkContentJournalPortal {
  33% {
    opacity: .8;
    transform: translateY(-5px)scale(.96)rotateZ(-1.5deg);
  }

  67% {
    opacity: .7;
    transform: translateY(0)scale(.98)rotateZ(1deg);
  }
}

.RS-anim-journal-all-tablets {
  animation: 2.5s cubic-bezier(.39, .575, .565, 1) infinite animArtworkContentJournalAllTablets;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: top;
  transform: translate3d(0, 0, 0);
}

@keyframes animArtworkContentJournalAllTablets {
  33% {
    transform: translateY(0)scale(1);
  }

  67% {
    transform: translateY(8px)scale(.98);
  }
}

.RS-anim-journal-tablet {
  animation: 2.5s cubic-bezier(.39, .575, .565, 1) infinite animArtworkContentJournalTablet;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform: translate3d(0, 0, 0);
}

.RS-anim-journal-tablet:nth-child(1) {
  animation-name: animArtworkContentJournalTabletAlt;
  animation-direction: reverse;
  animation-delay: .6s;
}

.RS-anim-journal-tablet:nth-child(2) {
  animation-delay: calc(var(--RS-anim-intro-artwork-delay)  + .375s);
}

.RS-anim-journal-tablet:nth-child(3) {
  animation-delay: calc(var(--RS-anim-intro-artwork-delay)  + .525s);
}

.RS-anim-journal-tablet:nth-child(4) {
  animation-delay: calc(var(--RS-anim-intro-artwork-delay)  + .15s);
}

.RS-anim-journal-tablet:nth-child(5) {
  animation-delay: calc(var(--RS-anim-intro-artwork-delay)  + 75ms);
  animation-name: animArtworkContentJournalTabletAlt;
  animation-direction: reverse;
}

.RS-anim-journal-tablet:nth-child(6) {
  animation-delay: calc(var(--RS-anim-intro-artwork-delay)  + .45s);
}

.RS-anim-journal-tablet:nth-child(7) {
  animation-delay: calc(var(--RS-anim-intro-artwork-delay)  + .225s);
  animation-name: animArtworkContentJournalTabletAlt;
  animation-direction: reverse;
}

@keyframes animArtworkContentJournalTablet {
  33% {
    transform: translate3d(0, 5px, 0)rotateZ(2deg)scale(.99);
  }

  67% {
    transform: translate3d(0, -3px, 0)rotateZ(-1deg)scale(1);
  }
}

@keyframes animArtworkContentJournalTabletAlt {
  33% {
    transform: translate3d(0, -1px, 0)rotateZ(-3deg)scale(1);
  }

  67% {
    transform: translate3d(0, 5px, 0)rotateZ(3deg)scale(.97);
  }
}

.RS-anim-journal-finger {
  animation: 2s linear infinite animArtworkContentJournalFinger;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: 100% 100%;
  transform: translate3d(0, 0, 0);
}

.RS-anim-journal-finger.first {
  animation-delay: calc(var(--RS-anim-intro-artwork-delay)  + .15s);
}

.RS-anim-journal-finger.second {
  animation-delay: calc(var(--RS-anim-intro-artwork-delay)  + .3s);
}

.RS-anim-journal-finger.third-fourth {
  animation-delay: calc(var(--RS-anim-intro-artwork-delay)  + .45s);
}

@keyframes animArtworkContentJournalFinger {
  33% {
    transform: rotateZ(3deg)scaleY(.95);
  }

  67% {
    transform: rotateZ(-3deg)scaleY(1.05);
  }
}

.RS-anim-journal-thumb {
  animation: 2s linear infinite animArtworkContentJournalThumb;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: 100% 100%;
  transform: translate3d(0, 0, 0);
}

@keyframes animArtworkContentJournalThumb {
  33% {
    transform: rotateZ(-1deg)scaleY(.97)scaleX(.99);
  }

  67% {
    transform: rotateZ(1deg)scaleY(1.03)scaleX(1);
  }
}

.RS-anim-journal-heart {
  animation: 1.5s linear infinite animArtworkContentJournalHeart;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  opacity: 0;
}

@keyframes animArtworkContentJournalHeart {
  40%, 90% {
    opacity: 0;
  }

  60%, 70% {
    opacity: 1;
  }
}

.RS-anim-journal-play {
  animation: 1s linear infinite animArtworkContentJournalPlay;
  animation-delay: var(--RS-anim-intro-artwork-delay);
}

@keyframes animArtworkContentJournalPlay {
  50% {
    transform: scale(.85);
  }
}

.RS-anim-journal-arm {
  animation: 3s linear infinite animArtworkContentJournalArm;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: bottom;
  transform: translate3d(0, 0, 0);
}

@keyframes animArtworkContentJournalArm {
  33% {
    transform: translateY(3px)rotateZ(-.5deg)scaleX(.98);
  }

  67% {
    transform: rotateZ(.75deg)scaleX(1);
  }
}

.RS-anim-full-rotate {
  animation: animFullRotate var(--RS-anim-full-rotate-duration) linear infinite;
}

@keyframes animFullRotate {
  100% {
    transform: rotateZ(360deg);
  }
}

.RS-anim-full-counter-rotate {
  animation: animFullCounterRotate var(--RS-anim-full-rotate-duration) linear infinite;
}

@keyframes animFullCounterRotate {
  100% {
    transform: rotateZ(-360deg);
  }
}

.RS-intro-founder-background-orbit:nth-of-type(2).RS-anim-full-rotate, .RS-intro-founder-background-orbit:nth-of-type(2) > .RS-anim-full-counter-rotate {
  animation-duration: calc(var(--RS-anim-full-rotate-duration) * 1.5);
}

.RS-intro-founder-background-orbit:nth-of-type(3).RS-anim-full-rotate, .RS-intro-founder-background-orbit:nth-of-type(3) > .RS-anim-full-counter-rotate {
  animation-duration: calc(var(--RS-anim-full-rotate-duration) * 2);
}

.RS-anim-intro-founder-icon-inner {
  animation: 5s linear infinite animIntroFounderIconInner;
  animation-delay: var(--RS-anim-intro-artwork-delay);
}

@keyframes animIntroFounderIconInner {
  50% {
    opacity: .67;
  }
}

.RS-anim-intro-founder-logo-inner {
  animation: 10s linear infinite animIntroFounderLogoInner;
  animation-delay: var(--RS-anim-intro-artwork-delay);
}

@keyframes animIntroFounderLogoInner {
  50% {
    opacity: .34;
  }
}

.RS-anim-mech-full {
  animation: .6s linear infinite animArtworkContentMechFull;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: bottom;
  transform: translate3d(0, 0, 0);
}

@keyframes animArtworkContentMechFull {
  33% {
    transform: scale(1.0035, .9975);
  }

  50% {
    transform: scale(1);
  }
}

.RS-anim-mech-sun {
  animation: 9s linear infinite animArtworkContentMechSun;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform: translate3d(0, 0, 0);
}

@keyframes animArtworkContentMechSun {
  50% {
    opacity: .9;
    transform: translateY(16%)scale(.9, .92);
  }
}

.RS-anim-mech-bulb {
  animation: 6s linear infinite animArtworkContentMechBulb;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform: translate3d(0, 0, 0);
}

@keyframes animArtworkContentMechBulb {
  50% {
    transform: translateY(-12%)scale(.98)rotateZ(-2deg);
  }

  75% {
    transform: translateY(-6%)scale(.99)rotateZ(1deg);
  }
}

.RS-anim-mech-bulb-light {
  animation: 6s linear infinite animArtworkContentMechBulbLight;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform: translate3d(0, 0, 0);
}

@keyframes animArtworkContentMechBulbLight {
  40%, 44% {
    opacity: .5;
  }

  38%, 42%, 52%, 56% {
    opacity: 1;
  }
}

.RS-anim-mech-laptop {
  animation: 3s linear infinite animArtworkContentMechLaptop;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform: translate3d(0, 0, 0);
}

@keyframes animArtworkContentMechLaptop {
  50% {
    transform: scale(.995)rotateZ(1deg);
  }
}

.RS-anim-mech-badge-left, .RS-anim-mech-badge-mid, .RS-anim-mech-badge-right, .RS-anim-mech-badge-top, .RS-anim-mech-badge-bottom {
  animation: 5.75s linear infinite animArtworkContentMechBadge;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  opacity: 0;
  transform: translate3d(0, 0, 0);
}

.RS-anim-mech-badge-bottom {
  animation-delay: calc(var(--RS-anim-intro-artwork-delay)  + var(--RS-anim-delay) * 3);
}

.RS-anim-mech-badge-right {
  animation-delay: calc(var(--RS-anim-intro-artwork-delay)  + var(--RS-anim-delay) * 6);
}

.RS-anim-mech-badge-mid {
  animation-delay: calc(var(--RS-anim-intro-artwork-delay)  + var(--RS-anim-delay) * 9);
}

.RS-anim-mech-badge-left {
  animation-delay: calc(var(--RS-anim-intro-artwork-delay)  + var(--RS-anim-delay) * 12);
}

@keyframes animArtworkContentMechBadge {
  0%, 48%, 52%, 100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

.RS-anim-mech-coffee-mist {
  animation: 3s linear infinite animArtworkContentMechCoffeeMist;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: top;
  opacity: 0;
  transform: translate3d(0, 0, 0);
}

@keyframes animArtworkContentMechCoffeeMist {
  0% {
    opacity: 0;
  }

  50% {
    opacity: .5;
    transform: scale(.86)translateX(15%);
  }

  100% {
    opacity: 0;
    transform: scale(.76)translateX(30%);
  }
}

.RS-anim-mech-head {
  animation: 3s linear infinite animArtworkContentMechHead;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform: translate3d(0, 0, 0);
}

@keyframes animArtworkContentMechHead {
  33% {
    transform: translateY(-1%)rotateZ(1.5deg);
  }

  67% {
    transform: translateY(1.25%)rotateZ(-2deg);
  }
}

.RS-anim-mech-hand-right-bottom {
  animation: 1.5s linear infinite animArtworkContentMechHandRightBottom;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: 100% 100%;
  transform: translate3d(0, 0, 0);
}

@keyframes animArtworkContentMechHandRightBottom {
  40%, 70% {
    transform: translateY(0%)rotateZ(0);
  }

  50% {
    transform: translateY(1%)rotateZ(-13deg)scaleX(.96);
  }

  60% {
    transform: rotateZ(3deg);
  }
}

.RS-anim-mech-knee {
  opacity: 0;
  animation: 4.25s linear infinite animArtworkContentMechKnee;
}

@keyframes animArtworkContentMechKnee {
  50%, 70% {
    opacity: 1;
  }

  40%, 80% {
    opacity: 0;
  }
}

.RS-anim-mech-helmet-glare {
  animation: 1s linear infinite animArtworkContentMechHelmetGlare;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: 100% 0;
}

@keyframes animArtworkContentMechHelmetGlare {
  0% {
    opacity: 0;
    transform: translateX(-25%);
  }

  50% {
    opacity: 1;
    transform: scaleX(.86)translateX(15%);
  }

  100% {
    opacity: 0;
    transform: scaleX(.6)translateX(20%);
  }
}

.RS-anim-mech-antenna-first, .RS-anim-mech-antenna-third {
  animation: 2.75s linear infinite animArtworkContentMechAntennaThird;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: 100% 100%;
}

@keyframes animArtworkContentMechAntennaThird {
  40%, 70% {
    transform: rotateZ(0);
  }

  50% {
    transform: rotateZ(-13deg)scaleX(.96);
  }
}

.RS-anim-mech-antenna-second, .RS-anim-mech-antenna-fourth {
  animation: 3.75s linear infinite animArtworkContentMechAntennaFourth;
  animation-delay: var(--RS-anim-intro-artwork-delay);
  transform-origin: 0 100%;
}

@keyframes animArtworkContentMechAntennaFourth {
  40%, 70% {
    transform: rotateZ(0);
  }

  50% {
    transform: rotateZ(21deg)scaleX(.96);
  }
}

.RS-anim-mech-antenna-third, .RS-anim-mech-antenna-fourth {
  animation-delay: calc(var(--RS-anim-intro-artwork-delay)  + var(--RS-anim-delay) * 2);
}

.RS-anim-mech-phone {
  animation: 3s linear infinite animArtworkContentMechPhone;
  animation-delay: var(--RS-anim-intro-artwork-delay);
}

@keyframes animArtworkContentMechPhone {
  40%, 44%, 48% {
    transform: rotateZ(-.5deg)scale(.98);
  }

  50%, 54%, 58% {
    transform: rotateZ(1deg)scale(.97);
  }

  38%, 42%, 46%, 52%, 56%, 60% {
    transform: rotateZ(0);
  }
}

.RS-looped-animation {
  backface-visibility: hidden;
  will-change: transform;
  animation-play-state: paused !important;
}

.RS-looped-animation--running {
  animation-play-state: running !important;
}

.RS-top-bar {
  z-index: 666;
  width: 100%;
  padding: var(--RS-space-24);
  transition: transform var(--RS-anim-duration-lg) var(--RS-default-transition);
  backface-visibility: hidden;
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  position: fixed;
  inset: 0 0 auto;
  transform: translate3d(0, 0, 0);
}

@media only screen and (min-width: 860px) {
  .RS-top-bar {
    padding: var(--RS-space-24) var(--RS-space-32);
  }
}

.RS-top-bar:before {
  content: "";
  width: inherit;
  height: 100%;
  z-index: -1;
  background-image: linear-gradient(#100f0fe3 0%, #100f0f57 67%, #100f0f00 100%);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.RS-top-bar--hidden {
  transform: translate3d(0, -100%, 0);
}

.RS-top-bar__container {
  max-width: var(--RS-col-8);
  flex-flow: row;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

.RS-top-bar__brand {
  z-index: 1;
}

.RS-top-bar__brand--connect {
  gap: var(--RS-space-24);
  display: flex;
  position: relative;
}

.RS-top-bar__brand--connect > span:after {
  content: "@";
  left: calc(var(--RS-space-24-n)  - var(--RS-space-2-n));
  font-size: calc(var(--RS-space-12)  + var(--RS-space-2));
  opacity: .67;
  position: absolute;
  top: 28%;
}

.RS-top-bar__logo {
  width: 108px;
  height: 34px;
  margin: 0 var(--RS-space-8) 0 0;
}

.RS-top-bar__logo--connect {
  width: 116px;
}

.RS-top-bar__nav {
  height: 100vh;
  width: 100vw;
  background: var(--RS-the-red-color);
  top: var(--RS-space-24-n);
  left: var(--RS-space-24-n);
  justify-content: center;
  align-items: flex-start;
  gap: var(--RS-space-32);
  transition: transform var(--RS-anim-duration-md) var(--RS-default-transition);
  font-size: var(--RS-font-size-h1-intro);
  padding: 10vh var(--RS-space-24) var(--RS-space-24) var(--RS-space-24);
  flex-direction: column;
  display: none;
  position: fixed;
  transform: translateX(100%);
}

@media only screen and (min-width: 860px) {
  .RS-top-bar__nav {
    height: inherit;
    justify-content: flex-end;
    gap: calc(var(--RS-space-24)  - var(--RS-space-4));
    width: auto;
    width: initial;
    font-size: inherit;
    background: none;
    background: initial;
    top: auto;
    top: initial;
    left: auto;
    left: initial;
    padding: 0;
    padding: initial;
    font-size: var(--RS-font-size-top-nav);
    flex-direction: row;
    align-items: center;
    margin-left: auto;
    display: flex;
    position: relative;
    transform: none;
  }
}

@media only screen and (min-width: 1024px) {
  .RS-top-bar__nav {
    gap: calc(var(--RS-space-32)  + var(--RS-space-4));
  }
}

.RS-top-bar__nav--displayed {
  display: flex;
}

.RS-top-bar__nav--opened {
  transform: translateX(0%);
}

.RS-top-bar__nav > .RS-top-bar__elem > .RS-link {
  padding-bottom: var(--RS-space-4);
  opacity: 0;
  transform: translateY(var(--RS-space-32-n));
  transition: transform var(--RS-anim-duration-md) var(--RS-default-transition), opacity var(--RS-anim-duration-md) var(--RS-default-transition);
  font-weight: var(--RS-font-weight-variable-regular);
  letter-spacing: .25px;
  display: block;
}

@media only screen and (min-width: 860px) {
  .RS-top-bar__nav > .RS-top-bar__elem > .RS-link {
    opacity: 1;
    font-weight: var(--RS-font-weight-variable-bold);
    display: inline;
    display: initial;
    transform: none;
  }
}

.RS-top-bar__nav > .RS-top-bar__elem > .RS-link--visible {
  opacity: 1;
  transform: none;
}

.RS-top-bar__nav > .RS-top-bar__elem:nth-of-type(1) > .RS-link {
  transition-delay: calc(var(--RS-anim-delay) * 1);
}

@media only screen and (min-width: 860px) {
  .RS-top-bar__nav > .RS-top-bar__elem:nth-of-type(1) > .RS-link {
    transition-delay: 0s;
    transition-delay: initial;
  }
}

.RS-top-bar__nav > .RS-top-bar__elem:nth-of-type(2) > .RS-link {
  transition-delay: calc(var(--RS-anim-delay) * 2);
}

@media only screen and (min-width: 860px) {
  .RS-top-bar__nav > .RS-top-bar__elem:nth-of-type(2) > .RS-link {
    transition-delay: 0s;
    transition-delay: initial;
  }
}

.RS-top-bar__nav > .RS-top-bar__elem:nth-of-type(3) > .RS-link {
  transition-delay: calc(var(--RS-anim-delay) * 3);
}

@media only screen and (min-width: 860px) {
  .RS-top-bar__nav > .RS-top-bar__elem:nth-of-type(3) > .RS-link {
    transition-delay: 0s;
    transition-delay: initial;
  }
}

.RS-top-bar__nav > .RS-top-bar__elem:nth-of-type(4) > .RS-link {
  transition-delay: calc(var(--RS-anim-delay) * 4);
}

@media only screen and (min-width: 860px) {
  .RS-top-bar__nav > .RS-top-bar__elem:nth-of-type(4) > .RS-link {
    transition-delay: 0s;
    transition-delay: initial;
  }
}

.RS-top-bar__nav > .RS-top-bar__elem:nth-of-type(5) > .RS-link {
  transition-delay: calc(var(--RS-anim-delay) * 5);
}

@media only screen and (min-width: 860px) {
  .RS-top-bar__nav > .RS-top-bar__elem:nth-of-type(5) > .RS-link {
    transition-delay: 0s;
    transition-delay: initial;
  }
}

.RS-top-bar__nav > .RS-top-bar__elem:nth-of-type(6) > .RS-link {
  transition-delay: calc(var(--RS-anim-delay) * 6);
}

@media only screen and (min-width: 860px) {
  .RS-top-bar__nav > .RS-top-bar__elem:nth-of-type(6) > .RS-link {
    transition-delay: 0s;
    transition-delay: initial;
  }
}

.RS-top-bar__nav > .RS-top-bar__elem:nth-of-type(7) > .RS-link {
  transition-delay: calc(var(--RS-anim-delay) * 7);
}

@media only screen and (min-width: 860px) {
  .RS-top-bar__nav > .RS-top-bar__elem:nth-of-type(7) > .RS-link {
    transition-delay: 0s;
    transition-delay: initial;
  }
}

.RS-top-bar__mobile-nav-icon {
  z-index: 1;
  -webkit-tap-highlight-color: transparent;
  margin-left: auto;
  display: block;
}

@media only screen and (min-width: 860px) {
  .RS-top-bar__mobile-nav-icon {
    display: none;
  }
}

.RS-top-bar__mobile-nav-icon span {
  width: calc(var(--RS-space-32)  + var(--RS-space-1));
  height: var(--RS-space-2);
  margin: var(--RS-space-6) auto;
  transition: transform var(--RS-anim-duration-md) var(--RS-default-transition);
  transform-origin: center;
  background-color: #ecf0f1;
  display: block;
}

.RS-top-bar__mobile-nav-icon--active span:nth-of-type(1) {
  transform: rotateZ(45deg) translateY(calc(var(--RS-space-6)  + 0px));
}

.RS-top-bar__mobile-nav-icon span:nth-of-type(2) {
  transform: translateX(var(--RS-space-6-n));
}

.RS-top-bar__mobile-nav-icon--active span:nth-of-type(2) {
  transform: rotateZ(-45deg) translateY(calc(var(--RS-space-6-n)  - 0px));
}

.hamburger:hover, .hamburger.focus-visible, .hamburger:hover, .hamburger.focus-visible {
  cursor: pointer;
}

.RS-top-bar__job-promo-caption {
  margin: 0 var(--RS-space-16) 0 0;
  display: inline-block;
}

@keyframes showTopBar {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.RS-top-bar__lang-switch {
  margin-left: var(--RS-space-16);
  flex-direction: row;
  padding-left: 0;
  display: flex;
}

.RS-top-bar__lang-switch > .RS-button {
  min-width: 86px;
  height: 42px;
  font-weight: var(--RS-font-weight-variable-bold);
  box-shadow: inset 0 0 0 2px var(--RS-mid-grey-color);
  color: inherit;
  justify-content: center;
  align-items: center;
  padding: 16px;
  display: flex;
}

.RS-top-bar__lang-switch > .RS-button:hover, .RS-top-bar__lang-switch > .RS-button.focus-visible {
  box-shadow: inset 0 0 0 2px var(--RS-mid-grey-color);
  background-color: #0000;
  background-color: initial;
  box-shadow: inset 0 0 0 2px var(--RS-mid-grey-color);
  background-color: #0000;
  background-color: initial;
  box-shadow: inset 0 0 0 2px var(--RS-mid-grey-color);
  background-color: #0000;
  background-color: initial;
}

.RS-top-bar__lang-switch > .RS-button:hover, .RS-top-bar__lang-switch > .RS-button.focus-visible {
  box-shadow: inset 0 0 0 2px var(--RS-mid-grey-color);
  background-color: #0000;
  background-color: initial;
}

.RS-top-bar__lang-switch > .RS-button > span {
  font-size: var(--RS-font-size-top-nav);
}

.RS-top-bar__lang-switch > .RS-button.RS-button--lang-active {
  background-color: var(--RS-mid-grey-color);
}

.RS-top-bar__lang-switch > .RS-button:first-of-type {
  border-radius: var(--RS-space-6) 0 0 var(--RS-space-6);
}

.RS-top-bar__lang-switch > .RS-button:last-of-type {
  border-radius: 0 var(--RS-space-6) var(--RS-space-6) 0;
  margin-left: var(--RS-space-2-n);
}

.RS-top-bar__lang-switch > .RS-button:before {
  width: 50%;
  left: 25%;
  bottom: var(--RS-space-2);
}

.RS-top-bar__lang-switch > .RS-button:first-of-type:after {
  content: "PL";
}

@media only screen and (min-width: 860px) {
  .RS-top-bar__lang-switch > .RS-button:first-of-type:after {
    content: "Polski";
  }
}

.RS-top-bar__lang-switch > .RS-button:last-of-type:after {
  content: "EN";
}

@media only screen and (min-width: 860px) {
  .RS-top-bar__lang-switch > .RS-button:last-of-type:after {
    content: "English";
  }
}

.RS-top-bar__elem {
  position: relative;
}

.RS-top-bar__dropdown {
  gap: calc(var(--RS-space-16)  + var(--RS-space-4));
  font-size: var(--RS-font-size-p);
  margin-top: var(--RS-space-24);
  font-weight: var(--RS-font-weight-variable-bold);
  padding: 0 0 0 var(--RS-space-16);
  text-wrap: nowrap;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

@media only screen and (min-width: 860px) {
  .RS-top-bar__dropdown {
    top: calc(100% + var(--RS-space-16));
    padding: var(--RS-space-24);
    font-size: inherit;
    font-weight: var(--RS-font-weight-variable-regular);
    margin-top: 0;
    display: none;
    position: absolute;
  }
}

.RS-top-bar__dropdown--displayed {
  display: flex;
}

@media only screen and (min-width: 860px) {
  .RS-top-bar__dropdown:before {
    content: "";
    width: 120%;
    height: 100%;
    border-radius: var(--RS-space-8);
    background-color: var(--RS-space-grey-color);
    border: var(--RS-space-1) solid var(--RS-white-color);
    border-radius: var(--RS-space-16);
    box-shadow: calc(var(--RS-space-1)  + var(--RS-space-2)) calc(var(--RS-space-1)  + var(--RS-space-2)) var(--RS-white-color);
    transition: transform var(--RS-anim-duration-md) var(--RS-default-transition), opacity var(--RS-anim-duration-md) var(--RS-default-transition);
    opacity: 0;
    transform: translateY(var(--RS-space-8-n)) scaleY(.67);
    transform-origin: top;
    display: block;
    position: absolute;
    top: 0;
    left: -10%;
  }

  .RS-top-bar__dropdown--visible:before {
    opacity: 1;
    transform: none;
  }

  .RS-top-bar__dropdown:after {
    content: "";
    width: 70%;
    height: var(--RS-space-16);
    left: 0%;
    top: var(--RS-space-16-n);
    opacity: 0;
    display: block;
    position: absolute;
  }
}

.RS-top-bar__dropdown > .RS-link {
  opacity: 0;
  transform: translateX(var(--RS-space-4-n));
  transition: transform var(--RS-anim-duration-md) var(--RS-default-transition), opacity var(--RS-anim-duration-md) var(--RS-default-transition);
  justify-content: space-between;
  align-items: center;
  gap: var(--RS-space-16);
  display: flex;
}

@media only screen and (min-width: 860px) {
  .RS-top-bar__dropdown > .RS-link {
    gap: var(--RS-space-32);
    padding-bottom: var(--RS-space-4);
    width: 100%;
    flex-direction: row;
  }
}

.RS-top-bar__dropdown--visible > .RS-link, .RS-top-bar__dropdown > .RS-link--visible {
  opacity: 1;
  transform: translateX(0);
}

.RS-top-bar__dropdown > .RS-link:nth-of-type(1) {
  transition-delay: calc(var(--RS-anim-delay) * 3.2);
}

@media only screen and (min-width: 860px) {
  .RS-top-bar__dropdown > .RS-link:nth-of-type(1) {
    transition-delay: calc(var(--RS-anim-duration-md) / 3 + var(--RS-anim-delay) * 0);
  }
}

.RS-top-bar__dropdown > .RS-link:nth-of-type(2) {
  transition-delay: calc(var(--RS-anim-delay) * 3.4);
}

@media only screen and (min-width: 860px) {
  .RS-top-bar__dropdown > .RS-link:nth-of-type(2) {
    transition-delay: calc(var(--RS-anim-duration-md) / 3 + var(--RS-anim-delay) * 1);
  }
}

.RS-top-bar__dropdown > .RS-link:nth-of-type(3) {
  transition-delay: calc(var(--RS-anim-delay) * 3.6);
}

@media only screen and (min-width: 860px) {
  .RS-top-bar__dropdown > .RS-link:nth-of-type(3) {
    transition-delay: calc(var(--RS-anim-duration-md) / 3 + var(--RS-anim-delay) * 2);
  }
}

.RS-top-bar__dropdown > .RS-link:nth-of-type(4) {
  transition-delay: calc(var(--RS-anim-delay) * 3.8);
}

@media only screen and (min-width: 860px) {
  .RS-top-bar__dropdown > .RS-link:nth-of-type(4) {
    transition-delay: calc(var(--RS-anim-duration-md) / 3 + var(--RS-anim-delay) * 3);
  }
}

.RS-top-bar__dropdown > .RS-link > .RS-inline-icon > svg {
  width: calc(var(--RS-space-24)  - var(--RS-space-4));
  height: calc(var(--RS-space-24)  - var(--RS-space-4));
}

.RS-top-bar__dropdown > .RS-link > .RS-inline-icon.RS-inline-icon--lg > svg {
  width: calc(var(--RS-space-24)  - var(--RS-space-2));
  height: calc(var(--RS-space-24)  - var(--RS-space-2));
}

.RS-top-bar__dropdown > .RS-link:before {
  display: none;
}

.RS-top-bar__dropdown > .RS-link > span:first-of-type {
  padding-bottom: var(--RS-space-4);
  position: relative;
}

.RS-top-bar__dropdown > .RS-link > span:first-of-type:before {
  content: "";
  width: 100%;
  height: var(--RS-space-2);
  bottom: var(--RS-space-2-n);
  border-radius: var(--RS-space-8);
  transform-origin: 100%;
  transition: transform var(--RS-anim-duration-lg) var(--RS-default-transition);
  background: var(--RS-white-color);
  position: absolute;
  left: 0;
  transform: scaleX(0);
}

.RS-top-bar__dropdown > .RS-link > span:first-of-type:hover:before, .RS-top-bar__dropdown > .RS-link > span:first-of-type.focus-visible:before, .RS-top-bar__dropdown > .RS-link > span:first-of-type:hover:before, .RS-top-bar__dropdown > .RS-link > span:first-of-type.focus-visible:before {
  transform-origin: 0;
  transform: scaleX(1);
}

.RS-button {
  width: 100%;
  color: var(--RS-bright-red-color);
  box-shadow: inset 0 0 0 2px var(--RS-bright-red-color);
  padding: 0 var(--RS-space-16);
  height: 48px;
  -webkit-tap-highlight-color: transparent;
  font-weight: var(--RS-font-weight-variable-bold);
  cursor: pointer;
  border-radius: 8px;
  outline: none;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: relative;
}

@media only screen and (min-width: 860px) {
  .RS-button {
    width: auto;
    min-width: calc(var(--RS-space-128)  + var(--RS-space-32));
    padding: var(--RS-space-12) var(--RS-space-16);
  }
}

.RS-button.RS-button--active, .RS-button:hover, .RS-button.focus-visible, .RS-button.RS-button--active, .RS-button:hover, .RS-button.focus-visible {
  color: var(--RS-white-color);
  background-color: var(--RS-bright-red-color);
  box-shadow: inset 0 0 0 0 var(--RS-bright-red-color);
}

.RS-button.RS-button--filled {
  color: var(--RS-white-color);
  background-color: var(--RS-the-red-color);
  box-shadow: inset 0 0 0 0 var(--RS-bright-red-color);
}

.RS-button.RS-button--filled:hover, .RS-button.RS-button--filled.focus-visible, .RS-button.RS-button--filled:hover, .RS-button.RS-button--filled.focus-visible, .RS-button.RS-button--enter-primary:hover, .RS-button.RS-button--enter-primary.focus-visible, .RS-button.RS-button--enter-primary:hover, .RS-button.RS-button--enter-primary.focus-visible {
  background-color: var(--RS-bright-red-color);
  box-shadow: inset 0 0 0 0 var(--RS-bright-red-color);
}

.RS-button > span {
  line-height: var(--RS-line-height-md);
  font-size: clamp(1.125rem, 1.1123rem + .0633vw, 1.1875rem);
  display: inline-block;
  position: relative;
}

.RS-button > svg.fa-arrow-right {
  margin: 0 0 0 var(--RS-space-16);
  transition: transform var(--RS-anim-duration-md) var(--RS-default-transition);
  font-size: clamp(1.25rem, 1.1994rem + .2532vw, 1.5rem);
  line-height: 1;
  transform: translate3d(0, 0, 0);
}

@media only screen and (min-width: 860px) {
  .RS-button.RS-button--small {
    padding: var(--RS-space-16);
    height: 42px;
    border-radius: var(--RS-space-6);
    opacity: 1;
    font-weight: var(--RS-font-weight-variable-bold);
  }

  .RS-button.RS-button--small > span {
    line-height: 1;
    font-size: var(--RS-font-size-top-nav);
    padding-bottom: var(--RS-space-4);
  }
}

.RS-button.RS-button--small > svg.fa-arrow-right {
  font-size: inherit;
  line-height: inherit;
  padding-bottom: inherit;
}

@media only screen and (min-width: 860px) {
  .RS-button.RS-button--small > svg.fa-arrow-right {
    margin: 0 0 0 var(--RS-space-8);
    padding-bottom: var(--RS-space-2);
  }
}

.RS-button--external > svg.fa-arrow-right {
  transform: translate3d(0, 0, 0)rotateZ(-45deg);
}

@media only screen and (min-width: 860px) {
  .RS-button--external > svg.fa-arrow-right {
    transform: translate3d(0, 0, 0);
  }
}

.RS-button--external:hover > svg.fa-arrow-right, .RS-button--external.focus-visible > svg.fa-arrow-right, .RS-button--external:hover > svg.fa-arrow-right, .RS-button--external.focus-visible > svg.fa-arrow-right {
  transform: translate3d(0, 0, 0)rotateZ(-45deg);
}

.RS-button--internal > svg.fa-arrow-right {
  transform: translate3d(0, 0, 0)rotateZ(45deg);
}

@media only screen and (min-width: 860px) {
  .RS-button--internal > svg.fa-arrow-right {
    transform: translate3d(0, 0, 0)rotateZ(45deg);
  }
}

.RS-button--internal:hover > svg.fa-arrow-right, .RS-button--internal.focus-visible > svg.fa-arrow-right, .RS-button--internal:hover > svg.fa-arrow-right, .RS-button--internal.focus-visible > svg.fa-arrow-right {
  transform: translate3d(0, 0, 0)rotateZ(90deg);
}

@media only screen and (min-width: 860px) {
  .RS-button--internal:hover > svg.fa-arrow-right, .RS-button--internal.focus-visible > svg.fa-arrow-right, .RS-button--internal:hover > svg.fa-arrow-right, .RS-button--internal.focus-visible > svg.fa-arrow-right {
    transform: translate3d(0, 0, 0)rotateZ(90deg);
  }
}

.RS-button--centered {
  margin: 0 auto;
}

.RS-intro .RS-button + .RS-button {
  margin-top: var(--RS-space-16);
}

@media only screen and (min-width: 480px) {
  .RS-intro .RS-button + .RS-button {
    margin-left: var(--RS-space-16);
  }
}

@media only screen and (min-width: 1024px) {
  .RS-intro .RS-button + .RS-button {
    margin-left: var(--RS-space-16);
  }
}

@media only screen and (min-width: 1280px) {
  .RS-intro .RS-button + .RS-button {
    margin-left: var(--RS-space-32);
  }
}

.RS-button.RS-button-link:hover, .RS-button.RS-button-link.focus-visible, .RS-button.RS-button-link:hover, .RS-button.RS-button-link.focus-visible {
  background-color: var(--RS-the-red-color);
  box-shadow: inset 0 0 0 0 var(--RS-bright-red-color);
}

.RS-button.RS-button--large {
  height: auto;
  padding: var(--RS-space-12) var(--RS-space-32);
}

.RS-button .RS-display--md {
  display: inherit;
}

@media only screen and (min-width: 1024px) {
  .RS-button .RS-display--md {
    display: none;
  }
}

.RS-button .RS-display--lg {
  display: none;
}

@media only screen and (min-width: 1024px) {
  .RS-button .RS-display--lg {
    display: inherit;
  }
}

.RS-top-bar__nav .RS-button > svg {
  display: none;
}

@media only screen and (min-width: 860px) {
  .RS-top-bar__nav .RS-button > svg {
    display: inline-block;
  }
}

.RS-top-bar .RS-button.RS-button--filled {
  background-color: var(--RS-bright-red-color);
}

@media only screen and (min-width: 860px) {
  .RS-top-bar .RS-button.RS-button--filled {
    background-color: var(--RS-the-red-color);
  }
}

.RS-footer {
  padding: var(--RS-space-64) var(--RS-space-24) var(--RS-space-32);
}

@media only screen and (min-width: 860px) {
  .RS-footer {
    padding: var(--RS-space-96) var(--RS-space-32) var(--RS-space-32);
  }
}

.RS-footer__container {
  max-width: var(--RS-col-8);
  margin: 0 auto;
}

.RS-footer__copyright {
  font-size: var(--RS-font-size-disclaimer);
  text-transform: uppercase;
  letter-spacing: .1em;
  font-weight: var(--RS-font-weight-variable-bold);
}

.RS-footer__navigation {
  margin: var(--RS-space-8) 0;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

@media only screen and (min-width: 860px) {
  .RS-footer__navigation {
    margin: var(--RS-space-48) 0;
    flex-direction: row;
  }
}

.RS-footer-col {
  font-size: var(--RS-font-size-label);
  line-height: var(--RS-line-height-xlg);
  margin-bottom: var(--RS-space-12);
  gap: var(--RS-space-12);
  display: flex;
}

@media only screen and (min-width: 860px) {
  .RS-footer-col {
    margin-bottom: 0;
    display: block;
  }
}

@media only screen and (min-width: 1280px) {
  .RS-footer-col {
    font-size: var(--RS-font-size-label-desktop);
  }
}

.RS-footer__container > .RS-footer-col {
  margin: 0;
}

.RS-footer-col .RS-link svg {
  margin-left: var(--RS-space-4);
}

.RS-cookie-bar {
  right: var(--RS-space-8);
  bottom: var(--RS-space-8);
  z-index: 666;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  opacity: 0;
  transition: transform var(--RS-anim-duration-xlg) var(--RS-default-transition), opacity var(--RS-anim-duration-md) var(--RS-default-transition);
  transform: translate3d(0, var(--RS-space-32), 0);
  backface-visibility: hidden;
  transform-style: preserve-3d;
  z-index: 1000000;
  margin: 8px;
  position: fixed;
}

@media only screen and (min-width: 860px) {
  .RS-cookie-bar {
    right: var(--RS-space-32);
    bottom: var(--RS-space-48);
  }
}

.RS-cookie-bar.RS-cookie-bar--visible {
  pointer-events: all;
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.RS-cookie-bar__box {
  max-width: 465px;
  padding: var(--RS-space-24) var(--RS-space-16);
  color: var(--RS-white-color);
  background-color: var(--RS-space-grey-color);
  border: 1px solid #000;
  border-radius: 16px;
  box-shadow: 8px 8px #000;
}

@media only screen and (min-width: 860px) {
  .RS-cookie-bar__box {
    padding: var(--RS-space-24) var(--RS-space-32);
  }
}

.RS-cookie-bar__content {
  padding: 0 var(--RS-space-8);
  flex-flow: column;
  display: flex;
}

.RS-cookie-bar__title {
  margin: 0 0 var(--RS-space-8);
  font-size: 32px;
  line-height: 40px;
}

@media only screen and (min-width: 860px) {
  .RS-cookie-bar__title {
    font-size: 40px;
    line-height: 48px;
  }
}

.RS-cookie-bar__title-icon {
  width: 32px;
  height: 32px;
  vertical-align: middle;
  fill: currentColor;
  display: inline-block;
  position: relative;
  top: -1px;
}

@media only screen and (min-width: 860px) {
  .RS-cookie-bar__title-icon {
    width: 40px;
    height: 48px;
    top: -2px;
  }
}

.RS-cookie-bar__description {
  padding: var(--RS-space-8) 0;
  margin: 0 0 var(--RS-space-16);
}

@media only screen and (min-width: 860px) {
  .RS-cookie-bar__description {
    margin: 0 0 var(--RS-space-32);
  }
}

.RS-cookie-bar__actions {
  flex-flow: wrap;
  justify-content: space-between;
  display: flex;
}

.RS-cookie-bar__actions > .RS-button {
  width: calc(50% - var(--RS-space-8));
}

/*# sourceMappingURL=index.2c9a837d.css.map */
